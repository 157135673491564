import { createTenantFirestore, firebaseStorage, firebaseAuth } from 'boot/firebase'
import Vue from 'vue'
export async function getDrawings({ commit }, houseType) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('drawings')
    .where('houseType', '==', houseType)
    .where('visible', '==', true)
    .orderBy('fileName')
    .get()
  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setDrawings', docs)
}

export async function getQaImagesForPlot({ commit, dispatch, state, rootState }, plotId) {
  const firebaseStore = await createTenantFirestore()

  const storageRef = firebaseStorage.ref()
  const items = await firebaseStore
    .collection('reports')
    .where('plot', '==', plotId)
    .where('qcItemImageCount', '>', 0)
    .limit(100)
    .get()
  const imageUrls = []
  if (!items.empty) {
    for (const doc of items.docs) {
      for (const image of doc.data().qcItemImages) {
        if (image.path) {
          const url = await storageRef.child(image.path).getDownloadURL()
          imageUrls.push(url)
        }
      }
    }
  }
  return imageUrls
}

export async function getPlotItems({ commit, dispatch, state, rootState }, plotId) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('plots')
    .doc(plotId)
    .collection(firebaseStore.invoiceItemsCollection())
    .where('status', '==', 'Unassigned')
    .where('_deleted', '==', false)
    .get()

  const draftItems = await firebaseStore
    .collection('draftInvoiceItems')
    .where('engineer', '==', firebaseAuth.currentUser.uid)
    .get()

  const profileRate = rootState.auth.userProfile.rate ? rootState.auth.userProfile.rate : 0 // if no profile, then kill price
  let unitMode = 'pounds'
  if (typeof rootState.invoices.invoiceSettings.unitMode !== 'undefined') {
    if (rootState.invoices.invoiceSettings.unitMode === 'hours') {
      unitMode = 'hours'
    }
  }

  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      const hasDraft = draftItems.docs.filter((rec) => {
        if (rec.data().plotItem === doc.id) {
          let draftContent = false
          for (const v of rec.data().values) {
            const valueIds = Object.getOwnPropertyNames(v)
            for (const id of Object.values(valueIds)) {
              if (v[id]) {
                draftContent = true
              }
            }
          }
          return draftContent
        }
      })
      docs.push({
        id: doc.id,
        selected: false, // fake, for selecting items on list view
        profileCost:
          unitMode === 'pounds'
            ? doc.data().baseCost + doc.data().baseCost * profileRate
            : doc.data().baseCost * profileRate,
        ...doc.data(),
        hasDraft: hasDraft.length > 0
      })
    })
    if (rootState.settings.general && rootState.settings.general.categorySortOverride) {
      Vue.helpers.overrideSort(rootState.categories.categories, docs)
    }
  }
  commit('setPlotItems', docs)
}

export async function getPlotsByName({ commit }, searchText) {
  const firebaseStore = await createTenantFirestore()
  var searchStr = null
  if (typeof searchText === 'string') {
    searchStr = searchText
  } else {
    searchStr = searchText.searchText
  }
  const items = await firebaseStore
    .collection('plots')
    .orderBy('lcPlotName')
    .startAt(searchStr.toLowerCase())
    .endAt(searchStr.toLowerCase() + '\uf8ff')
    .get()
  const docs = []
  if (!items.empty) {
    for (const doc of items.docs) {
      if (doc.data()._deleted) {
        continue
      }
      if (typeof searchText === 'object') {
        if (searchText.limitKey && searchText.limitValue) {
          if (doc.data()[searchText.limitKey] === searchText.limitValue) {
            docs.push({
              id: doc.id,
              ...doc.data()
            })
          }
        }
      } else {
        docs.push({
          id: doc.id,
          ...doc.data()
        })
      }
    }
  }
  commit('setSearchResults', docs)
}
export async function getPlotOptions({ commit }, plotId) {
  const firebaseStore = await createTenantFirestore()

  const plot = await firebaseStore.collection('plots').doc(plotId).get()
  return plot.data()
}
export async function getPlots({ commit }, { builderId, developmentId }) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('plots')
    .where('builder', '==', builderId)
    .where('development', '==', developmentId)
    .where('_deleted', '==', false)
    .get()
  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setPlots', docs)
}
