import { createTenantFirestore, firebaseAuth, firebaseFunctions } from 'boot/firebase'
import { firestoreAction } from 'vuexfire'
import { i18n } from 'src/boot/i18n'
import Helpers from 'src/plugins/helpers'

import moment from 'moment'

export async function hasWorkingInvoice({ commit, dispatch, state }) {
  const openInvoiceId = state.workingInvoice.id
  if (!openInvoiceId) {
    return false
  }
  const firebaseStore = await createTenantFirestore()
  const ref = await firebaseStore.collection('jobs').doc(openInvoiceId).get()
  if (!ref.exists) {
    return false
  }

  return true
}

export async function getNewInvoiceItemPath({ commit, dispatch, state }, invoiceId) {
  const user = firebaseAuth.currentUser
  if (!user) {
    throw new Error('User not signed in')
  }

  const tokenResult = await user.getIdTokenResult()
  const tenantId = tokenResult.claims.tenantId
  const firebaseStore = await createTenantFirestore()
  const newDoc = firebaseStore
    .collection('jobs')
    .doc(invoiceId)
    .collection(firebaseStore.invoiceItemsCollection())
    .doc()
  return {
    id: newDoc.id,
    invoiceId: invoiceId,
    path: `${tenantId}/account/jobs/${invoiceId}/${newDoc.id}`
  }
}

export async function addCustomItemToInvoice({ commit, dispatch, state }, item) {
  const firebaseStore = await createTenantFirestore()

  // Simpler method to add or update a Custom item that doesn't involve referencing plots or updating invoices
  let openInvoiceId = null
  let invoiceId = null
  let editingItem = false
  const newItem = JSON.parse(JSON.stringify(item))
  // Convert any string date values into Firestore dates
  const dateFields = newItem.fields.filter((f) => f.type === 'date')
  for (const obj of newItem.values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      if (dateFields.find((f) => f.id === id)) {
        const date = moment(obj[id], 'DD-MM-YYYY')
        if (date.isValid()) {
          obj[id] = new Date(date.format('YYYY-MM-DD'))
        }
      }
    }
  }
  const eventFields = newItem.fields.filter((f) => f.type === 'calendarEvent')
  for (const obj of newItem.values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      if (eventFields.find((f) => f.id === id)) {
        const dateStart = moment(obj[id].start, 'DD-MM-YYYY')
        const dateEnd = moment(obj[id].end, 'DD-MM-YYYY')
        if (dateStart.isValid() && dateEnd.isValid()) {
          obj[id] = {
            start: new Date(dateStart.format('YYYY-MM-DD')),
            end: new Date(dateEnd.format('YYYY-MM-DD'))
          }
        } else {
          obj[id] = {
            start: null,
            end: null
          }
        }
      }
    }
  }

  if (item.id) {
    // We are updating a Custom item
    editingItem = true
    newItem.dateTime = new Date()
    newItem.updatedAt = new Date()
    newItem.approvalStatus = 'Pending'
    newItem._deleted = false
    newItem.serverTimestamp = firebaseStore.firestore.FieldValue.serverTimestamp()
    newItem.isInvoiceItem = true
    invoiceId = item.invoice
    const invoiceRef = await firebaseStore.collection('jobs').doc(item.invoice).get()
    if (invoiceRef.data().status === 'Completed') {
      throw new Error(i18n.t('errors.invoiceIsCompleted'))
    }
    await firebaseStore
      .collection('jobs')
      .doc(item.invoice)
      .collection(firebaseStore.invoiceItemsCollection())
      .doc(item.id)
      .set(newItem, { merge: true })

    let hasCalendarEvent = false
    for (const field of newItem.fields) {
      if (field.type === 'calendarEvent') {
        hasCalendarEvent = field.id
      }
    }
    if (hasCalendarEvent) {
      console.log('UPDATE EVENT', newItem)
      for (const index in newItem.values) {
        const v = newItem.values[index][hasCalendarEvent]
        if (typeof v !== 'undefined') {
          newItem.startDate = moment.utc(v.start).local().format('YYYY-MM-DD 00:00:00').toString()
          newItem.endDate = moment.utc(v.end).local().format('YYYY-MM-DD 23:59:59').toString()
        }
      }
      const eventDoc = await firebaseStore
        .collection('calendars')
        .doc(newItem.config.calendar)
        .collection('events')
        .where('approvalItemId', '==', newItem.id)
        .get()
      if (!eventDoc.empty) {
        for (const doc of eventDoc.docs) {
          await firebaseStore
            .collection('calendars')
            .doc(newItem.config.calendar)
            .collection('events')
            .doc(doc.id)
            .set(newItem, { merge: true })
        }
      }
    }
  } else if (state.workingInvoice && Object.keys(state.workingInvoice).length > 0) {
    editingItem = false
    openInvoiceId = state.workingInvoice.id
    const ref = await firebaseStore.collection('jobs').doc(openInvoiceId).get()
    if (!ref.exists) {
      throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
    }
    const wcDate = ref.data().wcDate
    newItem.invoice = openInvoiceId
    newItem.approvalStatus = 'Pending'
    newItem.dateTime = new Date()
    newItem.createdDate = new Date()
    newItem.wcDate = wcDate
    newItem._deleted = false
    newItem.serverTimestamp = firebaseStore.firestore.FieldValue.serverTimestamp()

    let newItemRef = null
    if (newItem.newDocRef) {
      // we know the new item ID already
      newItemRef = firebaseStore
        .collection('jobs')
        .doc(openInvoiceId)
        .collection(firebaseStore.invoiceItemsCollection())
        .doc(newItem.newDocRef)
    } else {
      newItemRef = firebaseStore
        .collection('jobs')
        .doc(openInvoiceId)
        .collection(firebaseStore.invoiceItemsCollection())
        .doc()
    }
    delete newItem.newDocRef
    await newItemRef.set(newItem)

    let hasCalendarEvent = false
    for (const field of newItem.fields) {
      if (field.type === 'calendarEvent') {
        hasCalendarEvent = field.id
      }
    }
    if (hasCalendarEvent) {
      if (eventFields.length > 0) {
        newItem.calendar = newItem.config.calendar // the ID of the calendar to add to
        newItem.isCalendarItem = true
        newItem.approvalItemId = newItemRef.id

        for (const index in newItem.values) {
          const v = newItem.values[index][hasCalendarEvent]
          if (typeof v !== 'undefined') {
            // make some FullCalendar friendly dates for ease
            newItem.startDate = moment.utc(v.start).local().format('YYYY-MM-DD 00:00:00').toString()
            newItem.endDate = moment.utc(v.end).local().format('YYYY-MM-DD 23:59:59').toString()
          }
        }

        console.log('ADD EVENT', newItem)
        const eventRef = firebaseStore.collection('calendars').doc(newItem.config.calendar).collection('events').doc()
        eventRef.set(newItem)
      }
    }
  } else {
    // No where to put these items
    throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
  }
  const syncFn = firebaseFunctions.httpsCallable('invoices-syncInvoiceWithItems')
  if (editingItem) {
    await syncFn({ invoices: invoiceId })
  } else if (openInvoiceId) {
    await syncFn({ invoices: openInvoiceId })
  }
}

export async function getCustomApprovalItems({ commit, dispatch, state, rootState }) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('approvalCategories')
    .where('active', '==', true)
    .orderBy('position')
    .get()
  const docs = []

  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }

  commit('setCustomApprovalItems', docs)
}

export async function getInvoiceStatus({ commit }, invoiceId) {
  const firebaseStore = await createTenantFirestore()

  const docRef = await firebaseStore.collection('jobs').doc(invoiceId).get()
  if (!docRef.exists) {
    throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
  }
  return docRef.data().status
}

export const getEngineerApprovalFeed = firestoreAction(async (context) => {
  console.log('[*] Binding to LIVE declined feed')
  const toDate = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59) // midnight today
  let fromDate = moment(new Date())
  fromDate = toDate.clone().subtract(28, 'day') // only show last 28 days
  const firebaseStore = await createTenantFirestore()

  return context.bindFirestoreRef(
    'declinedItems',
    firebaseStore
      .collection('approvals')
      .where('engineer', '==', firebaseAuth.currentUser.uid)
      .where('approvalStatus', '==', 'Declined')
      .where('dateTime', '>=', new Date(fromDate.format('YYYY/MM/DD HH:mm:ss')))
      .where('dateTime', '<=', new Date(toDate.format('YYYY/MM/DD HH:mm:ss')))
      .orderBy('dateTime', 'desc')
  )
})

export const stopLiveFeeds = firestoreAction(({ unbindFirestoreRef }) => {
  // unbindFirestoreRef('declinedItems') -- this one is needed for the badge status
  unbindFirestoreRef('pendingItems')
  unbindFirestoreRef('recentlyApproved')
  unbindFirestoreRef('draftItems')
})

export const getEngineerPendingFeed = firestoreAction(async (context) => {
  console.log('[*] Binding to LIVE pending feed')
  const firebaseStore = await createTenantFirestore()

  return context.bindFirestoreRef(
    'pendingItems',
    firebaseStore
      .collection('approvals')
      .where('engineer', '==', firebaseAuth.currentUser.uid)
      .where('approvalStatus', '==', 'Pending')
      .orderBy('dateTime', 'desc')
  )
})

export const getEngineerDraftFeed = firestoreAction(async (context) => {
  console.log('[*] Binding to LIVE draft feed')
  const firebaseStore = await createTenantFirestore()

  return context.bindFirestoreRef(
    'draftItems',
    firebaseStore
      .collection('draftApprovals')
      .where('engineer', '==', firebaseAuth.currentUser.uid)
      .orderBy('dateTime', 'desc')
  )
})

export const getEngineerApprovedFeed = firestoreAction(async (context) => {
  const firebaseStore = await createTenantFirestore()

  console.log('[*] Binding to LIVE approved feed')
  return context.bindFirestoreRef(
    'recentlyApproved',
    firebaseStore
      .collection('approvals')
      .where('engineer', '==', firebaseAuth.currentUser.uid)
      .where('approvalStatus', '==', 'Approved')
      .limit(5)
      .orderBy('dateTime', 'desc')
  )
})

export async function getEngineerRecentlyApproved({ commit }, limit = 5) {
  const firebaseStore = await createTenantFirestore()

  const itemsSnapshot = await firebaseStore
    .collection('approvals')
    .where('engineer', '==', firebaseAuth.currentUser.uid)
    .where('approvalStatus', '==', 'Approved')
    .orderBy('dateTime', 'desc')
    .limit(limit)
    .get()

  const docs = []
  for (const doc of itemsSnapshot.docs) {
    docs.push({
      id: doc.id,
      ...doc.data()
    })
  }
  commit('setEngineerRecentlyApproved', docs)
}

export async function removeInvoiceItems({ commit }, items) {
  const firebaseStore = await createTenantFirestore()

  // items = single or multiple
  // mark associated plot item as Unassigned
  // remove items from invoice
  // update invoice total
  // if no items in the invoice, set total to 0  - 18/03/2021
  // reload invoice will be done by parent
  const batch = firebaseStore.batch()
  let invoiceId = null
  // let itemsTotal = 0
  // delete invoice items and collect total
  const storageItemsToDelete = []
  for (const doc of items) {
    if (!invoiceId) {
      // Set invoice Id for use later
      invoiceId = doc.invoice
    }
    // const invoiceRef = await firebaseStore.collection('jobs').doc(doc.invoice).get()
    // if (invoiceRef.data().status === 'Completed') {
    //   throw new Error(i18n.t('errors.invoiceIsCompleted'))
    // }
    const invoiceItem = await firebaseStore
      .collection('jobs')
      .doc(doc.invoice)
      .collection(firebaseStore.invoiceItemsCollection())
      .doc(doc.id)
      .get()
    if (!invoiceItem.empty) {
      if (invoiceItem.data().config && invoiceItem.data().config.calendar) {
        // Delete related calendar events
        const eventSnapshot = await firebaseStore
          .collection('calendars')
          .doc(invoiceItem.data().config.calendar)
          .collection('events')
          .where('approvalItemId', '==', doc.id)
          .get()
        for (const event of eventSnapshot.docs) {
          event.ref.delete()
        }
      }

      if (typeof invoiceItem.data().hasVariationOrder !== 'undefined' && invoiceItem.data().hasVariationOrder) {
        storageItemsToDelete.push(invoiceItem.data().plotItem)
      }
      if (typeof invoiceItem.data().isDayworksItem !== 'undefined' && invoiceItem.data().isDayworksItem) {
        if (
          typeof invoiceItem.data().dayWorksImage !== 'undefined' &&
          Object.keys(invoiceItem.data().dayWorksImage).length > 0
        ) {
          storageItemsToDelete.push(invoiceItem.data().dayWorksImage.path.match(/(?:\/)([^/]+)(?=_)/)[1])
        }
      }
      if (typeof invoiceItem.data().isDayworksSignedItem !== 'undefined' && invoiceItem.data().isDayworksSignedItem) {
        if (
          typeof invoiceItem.data().dayWorksImage !== 'undefined' &&
          Object.keys(invoiceItem.data().dayWorksImage).length > 0
        ) {
          storageItemsToDelete.push(invoiceItem.data().dayWorksImage.path.match(/(?:\/)([^/]+)(?=_)/)[1])
        }
        if (typeof invoiceItem.data().signatureImage !== 'undefined' && invoiceItem.data().signatureImage.length > 0) {
          storageItemsToDelete.push(
            invoiceItem
              .data()
              .signatureImage.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
          )
        }
      }
      if (typeof invoiceItem.data().isCustomItem !== 'undefined' && invoiceItem.data().isCustomItem) {
        // Get Fields
        // Get values for Photo fields
        // Delete storage items
        const fields = invoiceItem.data().fields
        const values = invoiceItem.data().values
        const signatureFields = fields.filter((f) => f.type === 'signature')
        for (const sigField of signatureFields) {
          for (const index in values) {
            if (typeof values[index][sigField.id] !== 'undefined') {
              const value = values[index][sigField.id]
              if (value && value.signatureImage) {
                storageItemsToDelete.push(
                  value.signatureImage.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
                )
              }
            }
          }
        }
        const photoFields = fields.filter((f) => f.type === 'photo')
        for (const photoField of photoFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && value.path) {
                storageItemsToDelete.push(value.path.match(/(?:\/)([^/]+)(?=_)/)[1])
              }
            }
          }
        }
        // Get Fields
        // Get values for Photo Groups
        // Loop through each image in group
        // Delete storage items
        const photoGroupFields = fields.filter((f) => f.type === 'photoGroup')
        for (const photoField of photoGroupFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && Array.isArray(value)) {
                for (const image of value) {
                  if (image && image.path) {
                    storageItemsToDelete.push(image.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }

        // Get Fields
        // Get values for Document Scanners
        // Loop through each page
        // Delete storage items
        const scannerFields = fields.filter((f) => f.type === 'documentScanner')
        for (const scannerField of scannerFields) {
          for (const index in values) {
            if (typeof values[index][scannerField.id] !== 'undefined') {
              const value = values[index][scannerField.id]
              if (value && Array.isArray(value.pages)) {
                for (const page of value.pages) {
                  if (page && page.path) {
                    storageItemsToDelete.push(page.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }
      }

      if (typeof invoiceItem.data().isCustomSignedItem !== 'undefined' && invoiceItem.data().isCustomSignedItem) {
        // Get Fields
        // Get values for Photo fields
        // Delete storage items
        const fields = invoiceItem.data().fields
        const values = invoiceItem.data().values
        const photoFields = fields.filter((f) => f.type === 'photo')
        for (const photoField of photoFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && value.path) {
                storageItemsToDelete.push(value.path.match(/(?:\/)([^/]+)(?=_)/)[1])
              }
            }
          }
        }

        const signatureFields = fields.filter((f) => f.type === 'signature')
        for (const sigField of signatureFields) {
          for (const index in values) {
            if (typeof values[index][sigField.id] !== 'undefined') {
              const value = values[index][sigField.id]
              if (value && value.signatureImage) {
                storageItemsToDelete.push(
                  value.signatureImage.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
                )
              }
            }
          }
        }

        const photoGroupFields = fields.filter((f) => f.type === 'photoGroup')
        for (const photoField of photoGroupFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && Array.isArray(value)) {
                for (const image of value) {
                  if (image && image.path) {
                    storageItemsToDelete.push(image.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }
        const scannerFields = fields.filter((f) => f.type === 'documentScanner')
        for (const scannerField of scannerFields) {
          for (const index in values) {
            if (typeof values[index][scannerField.id] !== 'undefined') {
              const value = values[index][scannerField.id]
              if (value && Array.isArray(value.pages)) {
                for (const page of value.pages) {
                  if (page && page.path) {
                    storageItemsToDelete.push(page.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }
      }
      if (typeof invoiceItem.data().isCustomSignedItem !== 'undefined' && invoiceItem.data().isCustomSignedItem) {
        if (typeof invoiceItem.data().signatureImage !== 'undefined' && invoiceItem.data().signatureImage.length > 0) {
          storageItemsToDelete.push(
            invoiceItem
              .data()
              .signatureImage.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
          )
        }
      }
      if (typeof invoiceItem.data().isTravelItem !== 'undefined' && invoiceItem.data().isTravelItem) {
        if (
          typeof invoiceItem.data().dayWorksImage !== 'undefined' &&
          Object.keys(invoiceItem.data().dayWorksImage).length > 0
        ) {
          storageItemsToDelete.push(invoiceItem.data().dayWorksImage.path.match(/(?:\/)([^/]+)(?=_)/)[1])
        }
      }
      if (typeof invoiceItem.data().hasFormItem !== 'undefined' && invoiceItem.data().hasFormItem) {
        /* This is a custom form */
        const fields = invoiceItem.data().fields
        const values = invoiceItem.data().values
        const photoFields = fields.filter((f) => f.type === 'photo')
        for (const photoField of photoFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && value.path) {
                storageItemsToDelete.push(value.path.match(/(?:\/)([^/]+)(?=_)/)[1])
              }
            }
          }
        }
        const photoGroupFields = fields.filter((f) => f.type === 'photoGroup')
        for (const photoField of photoGroupFields) {
          for (const index in values) {
            if (typeof values[index][photoField.id] !== 'undefined') {
              const value = values[index][photoField.id]
              if (value && Array.isArray(value)) {
                for (const image of value) {
                  if (image && image.path) {
                    storageItemsToDelete.push(image.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }
        const scannerFields = fields.filter((f) => f.type === 'documentScanner')
        for (const scannerField of scannerFields) {
          for (const index in values) {
            if (typeof values[index][scannerField.id] !== 'undefined') {
              const value = values[index][scannerField.id]
              if (value && Array.isArray(value.pages)) {
                console.log(value)
                for (const page of value.pages) {
                  console.log(page)
                  if (page && page.path) {
                    storageItemsToDelete.push(page.path.match(/(?:\/)([^/]+)(?=_)/)[1])
                  }
                }
              }
            }
          }
        }
        const signatureFields = fields.filter((f) => f.type === 'signature')
        for (const sigField of signatureFields) {
          for (const index in values) {
            if (typeof values[index][sigField.id] !== 'undefined') {
              const value = values[index][sigField.id]
              if (value && value.path) {
                storageItemsToDelete.push(
                  value.path.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
                )
              }
            }
          }
        }
        if (typeof invoiceItem.data().signatureImage !== 'undefined' && invoiceItem.data().signatureImage.length > 0) {
          storageItemsToDelete.push(
            invoiceItem
              .data()
              .signatureImage.match(/\/signatures\/([a-zA-Z0-9-]+-\d{4}-\d{2}-\d{2}-[0-9a-f-]+)(?:\.jpg)?/)[1]
          )
        }
      }
      if (typeof invoiceItem.data().requirePhotos !== 'undefined' && invoiceItem.data().requirePhotos) {
        storageItemsToDelete.push(invoiceItem.data().plotItem)
      }
      if (typeof invoiceItem.data().qcItemImages !== 'undefined' && invoiceItem.data().qcItemImages.length > 0) {
        storageItemsToDelete.push(invoiceItem.data().plotItem)
      }
      batch.delete(invoiceItem.ref)
    }
  }
  // Update the plot status to Unassigned so it's available again
  for (const doc of items) {
    if (
      doc.isDayworksItem ||
      doc.isDayworksSignedItem ||
      doc.isTravelItem ||
      doc.isCustomItem ||
      doc.isCustomSignedItem
    ) {
      // Remove the item from Approvals feed too
      const approvalItem = await firebaseStore.collection('approvals').doc(doc.id).get()
      batch.delete(approvalItem.ref)
    } else {
      const plotItemRef = await firebaseStore
        .collection('plots')
        .doc(doc.plot)
        .collection(firebaseStore.invoiceItemsCollection())
        .doc(doc.plotItem)
        .get()
      if (!plotItemRef.empty) {
        batch.set(
          plotItemRef.ref,
          {
            status: 'Unassigned',
            serverTimestamp: firebaseStore.firestore.FieldValue.serverTimestamp()
          },
          { merge: true }
        )
      }
    }
  }

  await batch.commit()

  /* Make sure invoice totals are in sync with removed items */
  var syncFn = firebaseFunctions.httpsCallable('invoices-syncInvoiceWithItems')
  await syncFn({ invoices: invoiceId })
  /* Remove any files related to deleted items */
  var deleteFilesFn = firebaseFunctions.httpsCallable('plots-deleteFilesForPlotItem')
  console.log('FILES', storageItemsToDelete)
  await deleteFilesFn({ itemIds: storageItemsToDelete })
}

export async function getInvoiceItems({ commit }, invoiceId) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('jobs')
    .doc(invoiceId)
    .collection(firebaseStore.invoiceItemsCollection())
    .get()

  const docs = []

  if (!items.empty) {
    items.forEach((doc) => {
      if (!doc.data()._deleted) {
        docs.push({
          id: doc.id,
          ...doc.data()
        })
      }
    })
  }

  commit('setSelectedInvoiceItems', docs)
}

export async function getInvoice({ commit }, invoiceId) {
  const firebaseStore = await createTenantFirestore()

  const docRef = await firebaseStore.collection('jobs').doc(invoiceId).get()
  if (!docRef.exists) {
    commit('clearWorkingInvoice')
    throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
  }

  const invoice = {
    id: docRef.id,
    ...docRef.data()
  }
  const items = await firebaseStore
    .collection('jobs')
    .doc(invoiceId)
    .collection(firebaseStore.invoiceItemsCollection())
    .get()
  const invoiceItems = []
  if (!items.empty) {
    items.forEach((doc) => {
      invoiceItems.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  invoice.items = invoiceItems
  commit('setSelectedInvoice', invoice)
}

export async function addDayworksToInvoice({ commit, dispatch, state }, items) {
  const firebaseStore = await createTenantFirestore()

  // Simpler method to add or update a Dayworks item that doesn't involve referencing plots or updating invoices
  let openInvoiceId = null
  let invoiceIds = []
  let editingItems = false
  if (items.every((item) => item.id)) {
    // We are updating a Dayworks item. There should only be one item in the array but we'll handle more if given
    editingItems = true
    const batch = firebaseStore.batch()
    for (const item of items) {
      const newItem = JSON.parse(JSON.stringify(item))
      newItem.dateTime = new Date()
      newItem.updatedAt = new Date()
      newItem.approvalStatus = 'Pending'
      newItem._deleted = false
      newItem.serverTimestamp = firebaseStore.firestore.FieldValue.serverTimestamp()
      invoiceIds.push(item.invoice)
      const invoiceRef = await firebaseStore.collection('jobs').doc(item.invoice).get()
      if (invoiceRef.data().status === 'Completed') {
        throw new Error(i18n.t('errors.invoiceIsCompleted'))
      }
      const itemRef = firebaseStore
        .collection('jobs')
        .doc(item.invoice)
        .collection(firebaseStore.invoiceItemsCollection())
        .doc(item.id)
      batch.set(itemRef, newItem, { merge: true })
    }
    await batch.commit()
  } else if (state.workingInvoice && Object.keys(state.workingInvoice).length > 0) {
    editingItems = false
    openInvoiceId = state.workingInvoice.id
    const ref = await firebaseStore.collection('jobs').doc(openInvoiceId).get()
    if (!ref.exists) {
      throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
    }
    const wcDate = ref.data().wcDate
    const batch = firebaseStore.batch()
    for (const item of items) {
      const newItem = JSON.parse(JSON.stringify(item))
      newItem.invoice = openInvoiceId
      newItem.approvalStatus = 'Pending'
      newItem.dateTime = new Date()
      newItem.createdDate = new Date()
      newItem._deleted = false
      newItem.serverTimestamp = firebaseStore.firestore.FieldValue.serverTimestamp()
      newItem.wcDate = wcDate
      const itemRef = firebaseStore
        .collection('jobs')
        .doc(openInvoiceId)
        .collection(firebaseStore.invoiceItemsCollection())
        .doc()
      batch.set(itemRef, newItem)
    }
    await batch.commit()
  } else {
    // No where to put these items
    throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
  }
  const syncFn = firebaseFunctions.httpsCallable('invoices-syncInvoiceWithItems')
  if (editingItems) {
    invoiceIds = [...new Set(invoiceIds)]
    await syncFn({ invoices: invoiceIds })
  } else if (openInvoiceId) {
    await syncFn({ invoices: openInvoiceId })
  }
}

export async function isPlotItemAvailable({ commit }, item) {
  const firebaseStore = await createTenantFirestore()
  if (item.plot && item.plotItem) {
    const plotItemRef = firebaseStore
      .collection('plots')
      .doc(item.plot)
      .collection(firebaseStore.invoiceItemsCollection())
      .doc(item.plotItem)
    const plotItemAvailable = await plotItemRef.get()
    if (plotItemAvailable.exists) {
      if (plotItemAvailable.data().status.toLowerCase() === 'unassigned') {
        return true
      } else {
        return false
      }
    } else {
      throw new Error('Plot item no longer exists')
    }
  } else {
    return false
  }
}
export async function updateInvoice({ commit, dispatch, state }) {
  const firebaseStore = await createTenantFirestore()
  // Add items to invoice
  // Mark plot items as assigned
  const invoiceId = state.workingInvoice.id
  const ref = await firebaseStore.collection('jobs').doc(invoiceId).get()
  if (!ref.exists) {
    throw new Error(i18n.t('invoiceDetail.messages.invalidInvoice'))
  }
  // Quality Control addition June 2021
  // If state.editItemOptions is not empty, assume we're adding just an item with options
  let updateStandardItem = false
  let updatePlotItemWithOptions = false
  let updateInvoiceItemWithOptions = false
  let newItems = []
  if (Object.keys(state.editItemOptions).length > 0) {
    const rec = JSON.parse(JSON.stringify(state.editItemOptions))
    delete rec.hasDraft
    if (rec.fields) {
      const dateFields = rec.fields.filter((f) => f.type === 'date')
      for (const v of rec.values) {
        const valueIds = Object.getOwnPropertyNames(v)
        for (const id of Object.values(valueIds)) {
          if (dateFields.find((f) => f.id === id)) {
            const date = moment(v[id], 'DD-MM-YYYY')
            if (date.isValid()) {
              v[id] = new Date(date.format('YYYY-MM-DD'))
            }
          }
        }
      }
      const eventFields = rec.fields.filter((f) => f.type === 'calendarEvent')
      for (const v of rec.values) {
        const valueIds = Object.getOwnPropertyNames(v)
        for (const id of Object.values(valueIds)) {
          if (eventFields.find((f) => f.id === id)) {
            const dateStart = moment(v[id].start, 'DD-MM-YYYY')
            const dateEnd = moment(v[id].end, 'DD-MM-YYYY')
            if (dateStart.isValid() && dateEnd.isValid()) {
              v[id] = {
                start: new Date(dateStart.format('YYYY-MM-DD')),
                end: new Date(dateEnd.format('YYYY-MM-DD'))
              }
            } else {
              v[id] = {
                start: null,
                end: null
              }
            }
          }
        }
      }
    }

    newItems.push(rec)
    updatePlotItemWithOptions = true
  } else if (Object.keys(state.editInvoiceOptions).length > 0) {
    // PW-167 remove the fields here instead of in the mutation
    const rec = JSON.parse(JSON.stringify(state.editInvoiceOptions))
    delete rec.wcDate
    delete rec.dateTime
    newItems.push(rec)
    updateInvoiceItemWithOptions = true
  } else {
    newItems = state.selectedItems
    updateStandardItem = true
  }

  console.log('[*] Updating invoice items...')
  var updateFn = firebaseFunctions.httpsCallable('invoices-updateInvoiceItems')
  const response = await updateFn({
    invoiceId: invoiceId,
    invoiceItems: newItems,
    updateInvoiceItemWithOptions: updateInvoiceItemWithOptions
  })

  // Tidy up
  if (updateStandardItem) {
    // If we're adding items without options
    commit('clearSelectedItems')
    commit('setLastPlotsChosen', response.data.relatedPlots) // expand plots on invoice page
  } else if (updatePlotItemWithOptions) {
    commit('editItemOptions', {})
  } else if (updateInvoiceItemWithOptions) {
    commit('editInvoiceOptions', {})
  }
  if (response.data.itemsUnavailable) {
    Helpers.displayToast(i18n.t('invoices.messages.itemsUnavailable'), true)
  }
}
export async function createInvoice({ commit, dispatch, state, rootState }) {
  const firebaseStore = await createTenantFirestore()

  // Create a new invoice for this engineer and week commencing date
  let startDate = moment().startOf('isoweek')
  let endDate = startDate.clone().add(7, 'days')
  let slot = moment().month() // the current months index, from 0 e.g. May = 4
  let year = Number(new Date().getYear() + 1900)
  let wcDate = new Date(startDate.format('YYYY-MM-DD'))
  const today = new Date()

  const invoiceSettings = await firebaseStore.collection('settings').doc('invoices').get()

  if (invoiceSettings.data().invoicingDisabled) {
    throw new Error(i18n.t('errors.invoicingDisabled'))
  }
  if (state.invoiceMonthly) {
    // Grab the latest invoice settings
    if (invoiceSettings.data().yearSettings) {
      commit('invoices/setInvoiceSettings', invoiceSettings.data(), { root: true })
    } else {
      throw new Error(i18n.t('errors.noInvoiceSettings'))
    }
    // Get todays date and find what slot it belongs to from the invoiceYear
    slot = 0
    let foundSlot = false
    for (const month of state.invoiceYear) {
      if (today >= month.startDate && today <= month.endDate) {
        startDate = moment(month.startDate)
        endDate = moment(month.endDate)
        wcDate = new Date(startDate.format('YYYY-MM-DD')) // FIX
        year = Number(month.year)
        foundSlot = true
        console.log('Found Slot', startDate, endDate, year)
        break
      }
      slot++
    }
    if (slot > 11) {
      // PW-403 madness
      slot = 0
      wcDate = moment(endDate).startOf('month') // get the 1st day of the new month
      wcDate = new Date(wcDate.format('YYYY-MM-DD'))
    }
    if (!foundSlot) {
      throw new Error(i18n.t('invoices.messages.noInvoiceSlotAvailable'))
    }
  }

  let engineerName = ''
  if (rootState.auth.userProfile && rootState.auth.userProfile.sharedUser) {
    for (const name of rootState.auth.userProfile.names) {
      engineerName += `${name.firstName} ${name.lastName}, `
    }
    engineerName = engineerName.replace(/,\s*$/, '')
  } else {
    engineerName = `${rootState.auth.userProfile.firstName} ${rootState.auth.userProfile.lastName}`
  }
  const invoiceId = `${firebaseAuth.currentUser.uid}_${startDate.format('YYYY-MM-DD')}`
  const newInvoice = {
    wcDate: wcDate,
    startDate: new Date(startDate.format('YYYY-MM-DD')),
    endDate: new Date(endDate.format('YYYY-MM-DD')),
    _deleted: false,
    serverTimestamp: firebaseStore.firestore.FieldValue.serverTimestamp(),
    createdDate: today,
    slot: slot,
    year: year,
    monthly: state.invoiceMonthly,
    status: 'Active',
    total: 0,
    hasBonusRate: !!(rootState.auth.userProfile.bonusRate && rootState.auth.userProfile.bonusRate > 0),
    bonusRate: rootState.auth.userProfile.bonusRate ? rootState.auth.userProfile.bonusRate : 0,
    rate: rootState.auth.userProfile.rate ? rootState.auth.userProfile.rate : 0,
    invoiceTarget: rootState.auth.userProfile.invoiceTarget ? rootState.auth.userProfile.invoiceTarget : 0,
    engineer: firebaseAuth.currentUser.uid,
    engineerName: engineerName,
    engineerEmail: rootState.auth.userProfile.email
  }
  if (
    typeof rootState.auth.userProfile.invoiceTarget !== 'undefined' &&
    Number(rootState.auth.userProfile.invoiceTarget) > 0
  ) {
    newInvoice.invoiceTarget = Number(rootState.auth.userProfile.invoiceTarget)
  }
  console.log(JSON.stringify(newInvoice))

  // // Check if this invoice has already been created
  // const invoiceRef = await firebaseStore.collection('jobs')
  //   .where('engineer', '==', firebaseAuth.currentUser.uid)
  //   .where('wcDate', '>=', new Date(startDate.format('YYYY/MM/DD HH:mm:ss')))
  //   .where('wcDate', '<=', new Date(endDate.format('YYYY/MM/DD HH:mm:ss')))
  //   .get()
  // console.log(new Date(startDate.format('YYYY/MM/DD HH:mm:ss')))
  // console.log(new Date(startDate.format('YYYY/MM/DD HH:mm:ss')))
  //   console.log(invoiceRef)

  const invoiceExistsRef = await firebaseStore.collection('jobs').doc(invoiceId).get()
  if (invoiceExistsRef.exists) {
    throw new Error(i18n.t('invoices.messages.invoiceExists'))
  }
  await firebaseStore.collection('jobs').doc(invoiceId).set(newInvoice, {
    merge: true
  })
  commit('setWorkingInvoice', {
    id: invoiceId,
    ...newInvoice
  })
}
export async function getInvoices({ getters, commit, dispatch, state, rootState }) {
  const firebaseStore = await createTenantFirestore()

  const displayMonths =
    rootState.auth.userProfile && rootState.auth.userProfile.displayMonths
      ? Number(rootState.auth.userProfile.displayMonths)
      : 3
  const toDate = moment().clone().endOf('month') // last day of current month
  if (moment().month() === 11) {
    // PW-403 we're in December, need to look ahead to next year
    toDate.add(1, 'M').endOf('month')
  }
  const fromDate = moment().clone().subtract(displayMonths, 'months').startOf('month') // first day of month X months ago

  const items = await firebaseStore
    .collection('jobs')
    .where('engineer', '==', firebaseAuth.currentUser.uid)
    .where('wcDate', '>=', new Date(fromDate.format('YYYY/MM/DD HH:mm:ss')))
    .where('wcDate', '<=', new Date(toDate.format('YYYY/MM/DD HH:mm:ss')))
    .orderBy('wcDate', 'desc')
    .get()
  const docs = []

  for (const item of items.docs) {
    docs.push({
      ...item.data(),
      id: item.id
    })
  }
  commit('setInvoices', docs)

  // if (!items.empty) {
  //   items.forEach(doc => {
  //     docs.push({
  //       id: doc.id,
  //       ...doc.data()
  //     })
  //   })
  // }
}
